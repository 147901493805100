/* $(function() {
  var slide_main = $(".about-slide").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    speed: 1000,
    dots: false,
    pauseOnFocus: false,
    prevArrow: '<img src="images/index/about-prev.svg"  width="36" class="slide-arrow prev-arrow _about">',
    nextArrow: '<img src="images/index/about-next.svg"  width="36" class="slide-arrow next-arrow _about">',
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, //399px以下のサイズに適用
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
});
 */
//背景画像を読み込んでるか確認

/* var swiper = new Swiper(".swiper", {
  speed: 1000,
  slidesPerView: 1,
  autoplay: {
    delay: 2000
  },
  loop: true
}); */

if (window.matchMedia("(max-width:768px)").matches) {
  //スマフォ
  jQuery(function() {
    jQuery(".class-head").each(function() {
      jQuery(this).on("click", function() {
        jQuery(this).toggleClass("on");
        jQuery(this)
          .next(".class-txtbox")
          .slideToggle();
        return false;
      });
    });
  });
}
